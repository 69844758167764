import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export interface BudgetProps {
  name: string;
  tagId: number;
  limit: number;
  spent: number;
  balance: number;
  isRolledOver: 0 | 1;
}

const DashboardPage = () => {
  const [budgets, setBudgets] = useState<BudgetProps[] | undefined>();
  const [total, setTotal] = useState<any>();
  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState<string>('');

  const doLogin = useCallback(() => {
    (async () => {
      const {
        data: { status },
      } = await axios({
        url: 'https://giftedev.com/thesashki.php',
        method: 'post',
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          pass: password,
        },
      });

      if (status) {
        setLoggedIn(true);
      }

      setPassword('');
    })();
  }, [password]);

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    const getData = async () => {
      const {
        data: {
          response: { budgets: budgetsData },
        },
      } = await axios.get(
        `https://www.buxfer.com/api/budgets?token=${process.env.REACT_APP_BUXFER_TOKEN}`,
      );

      const totalData = {
        limit: 0,
        spent: 0,
        rollOver: 0,
        balance: 0,
      };

      (budgetsData as BudgetProps[]).forEach(
        ({ balance = 0, limit = 0, spent = 0 }) => {
          totalData.limit += limit;
          totalData.spent += spent;
          totalData.rollOver += balance - limit + spent;
          totalData.balance += balance;
        },
      );

      setBudgets(budgetsData);
      setTotal(totalData);
    };
    setInterval(() => getData(), 1000 * 60 * 60);
    getData();
  }, [loggedIn]);

  if (!loggedIn) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '5vh',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          columnGap: 1,
        }}
      >
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          size="small"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button variant="contained" size="medium" onClick={doLogin}>
          Login
        </Button>
      </Box>
    );
  }

  if (!budgets) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '5vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TableContainer
      sx={{ width: 650, margin: 'auto', marginTop: '5vh' }}
      component={Paper}
    >
      <Table aria-label="budget">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Категория</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Бюджет на месяц</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Потрачено</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Сэкономлено</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Доступно</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgets.map(
            ({
              balance = 0,
              isRolledOver,
              limit = 0,
              name,
              spent = 0,
              tagId,
            }) => {
              const rollOver = balance - limit + spent;
              return (
                <TableRow
                  key={tagId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell align="right">{limit.toFixed(2)}</TableCell>
                  <TableCell align="right" sx={{ color: '#e64b30' }}>
                    -{spent.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ color: rollOver >= 0 ? '#51b44f' : '#e64b30' }}
                  >
                    {isRolledOver ? rollOver.toFixed(2) : ''}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ color: balance >= 0 ? '#51b44f' : '#e64b30' }}
                  >
                    {balance.toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            },
          )}
          {total && (
            <TableRow
              key="total"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Total</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{total.limit.toFixed(2)}</strong>
              </TableCell>
              <TableCell align="right" sx={{ color: '#e64b30' }}>
                <strong>-{total.spent.toFixed(2)}</strong>
              </TableCell>
              <TableCell
                align="right"
                sx={{ color: total.rollOver >= 0 ? '#51b44f' : '#e64b30' }}
              >
                <strong>{total.rollOver.toFixed(2)}</strong>
              </TableCell>
              <TableCell
                align="right"
                sx={{ color: total.balance >= 0 ? '#51b44f' : '#e64b30' }}
              >
                <strong>{total.balance.toFixed(2)}</strong>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardPage;

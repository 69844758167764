import React from 'react';
// import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DashboardPage from './pages/dashboard-page/dashboard-page';
import './app.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/"></Route>
        <Route exact path="/budget">
          <DashboardPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
